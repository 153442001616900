<template>
  <BackofficeBase>
    <div class="overlay"></div>
	<div class="admin__wrapper">
		<div class="admin__side"  v-if="mobile_menu">
			<div class="side__content">
				<div class="admin__top">
					<a href="#"><img src="../../assets/img/logo.svg" alt="logo"></a>
					<ul>
						<li><a href="/backoffice/statistics"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13.4917 1.66663H6.50841C3.47508 1.66663 1.66675 3.47496 1.66675 6.50829V13.4833C1.66675 16.525 3.47508 18.3333 6.50841 18.3333H13.4834C16.5167 18.3333 18.3251 16.525 18.3251 13.4916V6.50829C18.3334 3.47496 16.5251 1.66663 13.4917 1.66663ZM14.3834 8.29996L12.4584 10.7833C12.2167 11.0916 11.8751 11.2916 11.4834 11.3333C11.0917 11.3833 10.7084 11.275 10.4001 11.0333L8.87508 9.83329C8.81675 9.78329 8.75008 9.78329 8.71675 9.79163C8.68341 9.79163 8.62508 9.80829 8.57508 9.87496L6.59175 12.45C6.46675 12.6083 6.28341 12.6916 6.10008 12.6916C5.96675 12.6916 5.83341 12.65 5.71675 12.5583C5.44175 12.35 5.39175 11.9583 5.60008 11.6833L7.58342 9.10829C7.82508 8.79996 8.16675 8.59996 8.55841 8.54996C8.94175 8.49996 9.33341 8.60829 9.64175 8.84996L11.1667 10.05C11.2251 10.1 11.2834 10.1 11.3251 10.0916C11.3584 10.0916 11.4167 10.075 11.4667 10.0083L13.3917 7.52496C13.6001 7.24996 14.0001 7.19996 14.2667 7.41663C14.5417 7.64163 14.5917 8.03329 14.3834 8.29996Z" fill="#464646"/>
						</svg>
						</span> Statistics</a></li>
						<li class="active__admin"><a href="backoffice/stock_definitions"><span><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0_266_965" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="15">
						<path d="M1 6.16675H3.66667V10.8334H1V6.16675ZM5.66667 4.16675H8.33333V12.8334H5.66667V4.16675Z" fill="#464646" stroke="white" stroke-width="1.16667" stroke-linejoin="round"/>
						<path d="M7 14.1667V12.8334V14.1667Z" fill="#464646"/>
						<path d="M7 14.1667V12.8334" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M10.3334 3.5H13V6.5H10.3334V3.5Z" fill="#464646" stroke="white" stroke-width="1.16667" stroke-linejoin="round"/>
						<path d="M2.33337 6.16671V2.83337V6.16671ZM11.6667 10.8334V6.50004V10.8334ZM11.6667 3.50004V0.833374V3.50004Z" fill="#464646"/>
						<path d="M2.33337 6.16671V2.83337M11.6667 10.8334V6.50004M11.6667 3.50004V0.833374" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
						</mask>
						<g mask="url(#mask0_266_965)">
						<path d="M-1 -0.5H15V15.5H-1V-0.5Z" fill="#464646"/>
						</g>
						</svg>
						</span> Stock Definitions</a></li>
						<li><a href="/backoffice/graphe_explanations"><span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M8.70833 0.5C3.89896 0.5 0 4.39896 0 9.20833C0 14.0177 3.89896 17.9167 8.70833 17.9167C13.5177 17.9167 17.4167 14.0177 17.4167 9.20833C17.4167 4.39896 13.5177 0.5 8.70833 0.5ZM8.3125 4.45833C8.10254 4.45833 7.90117 4.54174 7.75271 4.69021C7.60424 4.83867 7.52083 5.04004 7.52083 5.25C7.52083 5.45996 7.60424 5.66133 7.75271 5.80979C7.90117 5.95826 8.10254 6.04167 8.3125 6.04167H8.70833C8.9183 6.04167 9.11966 5.95826 9.26813 5.80979C9.41659 5.66133 9.5 5.45996 9.5 5.25C9.5 5.04004 9.41659 4.83867 9.26813 4.69021C9.11966 4.54174 8.9183 4.45833 8.70833 4.45833H8.3125ZM7.125 7.625C6.91504 7.625 6.71367 7.70841 6.56521 7.85687C6.41674 8.00534 6.33333 8.2067 6.33333 8.41667C6.33333 8.62663 6.41674 8.82799 6.56521 8.97646C6.71367 9.12493 6.91504 9.20833 7.125 9.20833H7.91667V11.5833H7.125C6.91504 11.5833 6.71367 11.6667 6.56521 11.8152C6.41674 11.9637 6.33333 12.165 6.33333 12.375C6.33333 12.585 6.41674 12.7863 6.56521 12.9348C6.71367 13.0833 6.91504 13.1667 7.125 13.1667H10.2917C10.5016 13.1667 10.703 13.0833 10.8515 12.9348C10.9999 12.7863 11.0833 12.585 11.0833 12.375C11.0833 12.165 10.9999 11.9637 10.8515 11.8152C10.703 11.6667 10.5016 11.5833 10.2917 11.5833H9.5V8.41667C9.5 8.2067 9.41659 8.00534 9.26813 7.85687C9.11966 7.70841 8.9183 7.625 8.70833 7.625H7.125Z" fill="#464646"/>
						</svg>
						</span> Graph Explanations</a></li>
						<li><a href="/backoffice/disclaimer"><span><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M8.70833 0.5C3.89896 0.5 0 4.39896 0 9.20833C0 14.0177 3.89896 17.9167 8.70833 17.9167C13.5177 17.9167 17.4167 14.0177 17.4167 9.20833C17.4167 4.39896 13.5177 0.5 8.70833 0.5ZM8.3125 4.45833C8.10254 4.45833 7.90117 4.54174 7.75271 4.69021C7.60424 4.83867 7.52083 5.04004 7.52083 5.25C7.52083 5.45996 7.60424 5.66133 7.75271 5.80979C7.90117 5.95826 8.10254 6.04167 8.3125 6.04167H8.70833C8.9183 6.04167 9.11966 5.95826 9.26813 5.80979C9.41659 5.66133 9.5 5.45996 9.5 5.25C9.5 5.04004 9.41659 4.83867 9.26813 4.69021C9.11966 4.54174 8.9183 4.45833 8.70833 4.45833H8.3125ZM7.125 7.625C6.91504 7.625 6.71367 7.70841 6.56521 7.85687C6.41674 8.00534 6.33333 8.2067 6.33333 8.41667C6.33333 8.62663 6.41674 8.82799 6.56521 8.97646C6.71367 9.12493 6.91504 9.20833 7.125 9.20833H7.91667V11.5833H7.125C6.91504 11.5833 6.71367 11.6667 6.56521 11.8152C6.41674 11.9637 6.33333 12.165 6.33333 12.375C6.33333 12.585 6.41674 12.7863 6.56521 12.9348C6.71367 13.0833 6.91504 13.1667 7.125 13.1667H10.2917C10.5016 13.1667 10.703 13.0833 10.8515 12.9348C10.9999 12.7863 11.0833 12.585 11.0833 12.375C11.0833 12.165 10.9999 11.9637 10.8515 11.8152C10.703 11.6667 10.5016 11.5833 10.2917 11.5833H9.5V8.41667C9.5 8.2067 9.41659 8.00534 9.26813 7.85687C9.11966 7.70841 8.9183 7.625 8.70833 7.625H7.125Z" fill="#464646"/>
						</svg></span> Disclaimer</a></li>
						<li><a href="/backoffice/email_list"><span><svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16.2 0.5H1.8C0.81 0.5 0.00899999 1.34375 0.00899999 2.375L0 13.625C0 14.6562 0.81 15.5 1.8 15.5H16.2C17.19 15.5 18 14.6562 18 13.625V2.375C18 1.34375 17.19 0.5 16.2 0.5ZM15.84 4.48438L9.477 8.62813C9.189 8.81563 8.811 8.81563 8.523 8.62813L2.16 4.48438C2.06975 4.4316 1.99073 4.36031 1.9277 4.2748C1.86467 4.18929 1.81896 4.09135 1.79332 3.98691C1.76768 3.88247 1.76265 3.77369 1.77854 3.66716C1.79443 3.56064 1.8309 3.45858 1.88575 3.36716C1.9406 3.27574 2.01269 3.19686 2.09765 3.13531C2.18262 3.07375 2.27868 3.03079 2.38005 3.00903C2.48141 2.98727 2.58595 2.98716 2.68736 3.0087C2.78876 3.03025 2.88492 3.073 2.97 3.13437L9 7.0625L15.03 3.13437C15.1151 3.073 15.2112 3.03025 15.3126 3.0087C15.414 2.98716 15.5186 2.98727 15.62 3.00903C15.7213 3.03079 15.8174 3.07375 15.9023 3.13531C15.9873 3.19686 16.0594 3.27574 16.1142 3.36716C16.1691 3.45858 16.2056 3.56064 16.2215 3.66716C16.2373 3.77369 16.2323 3.88247 16.2067 3.98691C16.181 4.09135 16.1353 4.18929 16.0723 4.2748C16.0093 4.36031 15.9302 4.4316 15.84 4.48438Z" fill="#464646"/>
						</svg>
						</span> Emailing List</a></li>
						<li><a href="/backoffice/admins"><span  class="double__image"><img src="../../assets/img/side5.svg" alt="side">
							<img src="../../assets/img/side5a.svg" alt="side" class="active">
						</span> Admins</a></li>
					</ul>
				</div>
				<div class="admin__bottom">
					<ul>
						<li>
							<a href="#"><span><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.87828 12.07C7.87828 11.66 8.21828 11.32 8.62828 11.32H14.1083V2.86C14.0983 2.38 13.7183 2 13.2383 2C7.34828 2 3.23828 6.11 3.23828 12C3.23828 17.89 7.34828 22 13.2383 22C13.7083 22 14.0983 21.62 14.0983 21.14V12.81H8.62828C8.20828 12.82 7.87828 12.48 7.87828 12.07Z" fill="#46474B"/>
							<path d="M20.5416 11.5402L17.7016 8.69016C17.4116 8.40016 16.9316 8.40016 16.6416 8.69016C16.3516 8.98016 16.3516 9.46016 16.6416 9.75016L18.2016 11.3102H14.1016V12.8102H18.1916L16.6316 14.3702C16.3416 14.6602 16.3416 15.1402 16.6316 15.4302C16.7816 15.5802 16.9716 15.6502 17.1616 15.6502C17.3516 15.6502 17.5416 15.5802 17.6916 15.4302L20.5316 12.5802C20.8316 12.3002 20.8316 11.8302 20.5416 11.5402Z" fill="#46474B"/>
							</svg>
							</span>Log out</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="admin__content">
			<div class="admin__box">
				<div class="menu__button" v-on:click="open_menu">
					<a href="#">
						<span></span>
						<span></span>
						<span></span>
					</a>
				</div>
				<div class="head__admin">
					<h6 class="secondary__font"> Sock Definitions</h6>
				</div>
				<div class="stock__wrapper">
					<div class="stock__grid">
						<div class="elem__stock">
							<span class="secondary__font">Stock of the day</span>
							<ul>
								<li class="secondary__font">{{ daily_stock }}</li>
							</ul>
							<div class="edit__stock">
								<a href="/backoffice/stock_day"><img src="../../assets/img/edit.svg" alt="edit"></a>
							</div>
						</div>

						<div class="elem__stock" >
							<span class="secondary__font">Top searched</span>
							<ul>
								<li class="secondary__font"  v-for="top_5 in top_five" :key="top_5.place_num">{{top_5.name}}</li>
							</ul>
							<div class="edit__stock">
								<a href="/backoffice/top_five"><img src="../../assets/img/edit.svg" alt="edit"></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

  </BackofficeBase>
</template>
<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel"


export default {
  name: "BackofficeStockDefinitions",
  components: {
    BackofficeBase,
  },
  data() {
    return {
        mobile_menu: true,
		top_five:[],
		daily_stock:''
    };
  },
  async mounted() {
    let backendModel = new BackendModel()
    let data = await backendModel.backendRequest("/Api/service/backoffice/get_sock_definitions", {})
    this.top_five = data.data.data.top_five
    this.daily_stock = data.data.data.daily_stock[0].name

    this.loader_prop = false
  },
  methods: {
 
    async open_menu() {
        this.mobile_menu =!this.mobile_menu
    },
  },
};
</script>
<style scoped>
@import "./style.css";
</style>
